import clsx from "clsx";
import LanguageSelect from "../LanguageSelect";
import styles from "./TopBar.module.scss";

const TopBar = (props: { className?: string }) => {
  const { className } = props;
  return (
    <div className={clsx(styles.Topbar, className)}>
      <div className={styles.Topbar__Logo}>
        <img src="/logo.svg" alt="Praice Logo" />
      </div>
      <div className={styles.Topbar__Actions}>
        <LanguageSelect />
      </div>
    </div>
  );
};

export default TopBar;
