export interface Trait {
  id: string;
  name: string;
  description: string;
  orderIndex: number;
}

export enum RelationTypes {
  Friend = 1,
  Family = 4,
  Professional = 5,
  Self = 6,
}

export interface Relation {
  id: RelationTypes;
  name: keyof typeof RelationTypes;
}

export interface PraicingInfoCategory {
  id: string;
  name: string;
  description: string;
  orderIndex: number;
  traits: Trait[];
}

export interface PraicingInfo {
  categories: PraicingInfoCategory[];
  relations: Relation[];
}

export interface Profile {
  id: string;
  firstname: string;
  lastname: string;
  sex: string;
  profileImage: string;
  shortId: string;
  backgroundImage: string;
  status: number;
  creationDate: string;
  version: number;
  email: string;
  phone: string;
  language: number;
}

export interface BasicInfo {
  id: string;
  key: string;
  value: string | number | { key: string; label: string } | any;
  validChars: string;
  updated: string;
  orderIndex: number;
}

export enum Locale {
  "da_DK" = 1,
  //TODO: should be updated in DB (en_US -> en_GB) once whole app is live
  "en_GB" = 2,
  "uk_UA" = 3,
  "nb_NO" = 4,
  "tr_TR" = 5,
}

export interface Language {
  id: Locale;
  locale: keyof typeof Locale;
  label?: string;
}

export interface Fingerprint {
  browserHash: string;
  data: any;
}
