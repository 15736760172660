import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { createBrowserHistory } from "history";

const initAppInsights = () => {
  const {
    REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_CONN_STRING,
  } = process.env;

  if (
    !REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY ||
    REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY === "" ||
    !REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_CONN_STRING ||
    REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_CONN_STRING === ""
  ) {
    return;
  }

  const browserHistory = createBrowserHistory();
  const reactPlugin = new ReactPlugin();

  const clickPluginInstance = new ClickAnalyticsPlugin();
  const clickPluginConfig = {
    autoCapture: true,
  };

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
      connectionString:
        REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_CONN_STRING,
      enableAutoRouteTracking: true,
      appId: "consumer-v3-frontend",
      autoTrackPageVisitTime: true,
      disableAjaxTracking: false,
      disableFetchTracking: false,
      enableRequestHeaderTracking: true,
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
    },
  });
  appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags["ai.cloud.role"] = "consumer-v3-frontend";
  });
  appInsights.loadAppInsights();
};

export default initAppInsights;
