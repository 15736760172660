import { i18n } from "@lingui/core";
import { Locale } from "types";
import { Language } from "types";

export const appLanguages: Language[] = [
  {
    id: Locale.en_GB,
    locale: "en_GB",
    label: "English",
  },
  {
    id: Locale.da_DK,
    locale: "da_DK",
    label: "Dansk",
  },
];

export const defaultLocale = "da_DK";

export async function dynamicActivate(locale: string) {
  const { messages } = await import(`./locales/${locale}`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
