import { useEffect, Suspense, lazy } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { defaultLocale, dynamicActivate } from "./i18n";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "layouts/AppLayout";
import initAppInsights from "utils/AppInsights";
import { Toaster } from "sonner";

import "styles/global.scss";

const PraicingPage = lazy(() => import("pages/Praicing"));
const WelcomePage = lazy(() => import("pages/Welcome"));
const NotFound = lazy(() => import("pages/NotFound"));
const Unauthorized = lazy(() => import("pages/Unauthorized"));

function App(): JSX.Element {
  initAppInsights();

  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: false } },
  });

  useEffect(() => {
    // Dynamically load the catalogs
    dynamicActivate(defaultLocale);
  }, []);

  return (
    <I18nProvider i18n={i18n}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<AppLayout />}>
            <Routes>
              <Route path="/" element={<NotFound />} />
              <Route path="/welcome" element={<WelcomePage />} />
              <Route path="/praice/:shortId" element={<PraicingPage />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/401" element={<Unauthorized />} />
            </Routes>
          </Suspense>
        </QueryClientProvider>
      </BrowserRouter>
      <Toaster position="top-right" closeButton={true} />
    </I18nProvider>
  );
}

export default App;
