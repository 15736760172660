import BaseLayout from "layouts/BaseLayout";
import TopBar from "components/shared/TopBar";

type AppLayoutProps = {
  title?: string;
  description?: string;
  children?: React.ReactNode;
};

const AppLayout = (props: AppLayoutProps) => {
  const { children, ...pageProps } = props;

  return (
    <BaseLayout {...pageProps}>
      <TopBar />
      <div id="main">{children}</div>
    </BaseLayout>
  );
};

export default AppLayout;
