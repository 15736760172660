import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
} from "react-aria-components";
import { appLanguages, dynamicActivate } from "i18n";
import { useLingui } from "@lingui/react";
import styles from "./LanguageSelect.module.scss";

const LanguageSelect = () => {
  const { i18n } = useLingui();

  const activeLanguage = appLanguages.find(
    (lang) => lang.locale === i18n.locale
  );

  return (
    <div className={styles.LanguageSelect}>
      <MenuTrigger>
        <Button aria-label="Language" className={styles.LanguageSelect__Button}>
          <img
            src={`/flags/${activeLanguage.locale}.svg`}
            alt={activeLanguage.label}
          />
        </Button>
        <Popover className={styles.LanguageSelectPopover}>
          <Menu>
            {appLanguages.map((lang) => (
              <MenuItem
                key={lang.id}
                onAction={() => dynamicActivate(lang.locale)}
                className={styles.LanguageSelect__Item}
              >
                <img src={`/flags/${lang.locale}.svg`} alt={lang.label} />
              </MenuItem>
            ))}
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>
  );
};

export default LanguageSelect;
