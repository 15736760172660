import { Helmet, HelmetProvider } from "react-helmet-async";
import TopBar from "components/shared/TopBar";

type BaseLayoutProps = {
  title?: string;
  description?: string;
  children?: React.ReactNode;
};

const BaseLayout = (props: BaseLayoutProps) => {
  const { title, description, children } = props;

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Praice" defaultTitle="Praice" defer={false}>
        {title && <title>{title}</title>}
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default BaseLayout;
